import { ErrorHandler, NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpBackend,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { SharedUiModule } from '@firebird-web/shared-ui';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouteStoreModule } from '@firebird-web/route-store';
import { environment } from '@firebird-web/shared-config';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouteSerializer } from './serializers/custom-route-serializer';
import { TitleCasePipe } from '@angular/common';
import { UtcDatePipe } from '@firebird-web/shared/pipes';
import { AuthorizationInterceptor } from '@firebird-web/acl';
import { CreateListService } from '../../../../libs/settings/src/lib/components/create-list/services';
import { AlertsNotificationsModule } from '@firebird-web/alerts-notifications';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from 'libs/acl/src/lib/auth.service';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { UserSettingApiService } from '../../../../libs/user-settings-store/src/lib';
import {
  getMetaReducers,
  getSettingsMetaReducers,
} from '../../../../libs/user-settings-store/src/lib/constants';
import { GridstackModule } from 'gridstack/dist/angular';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { SortablejsModule } from 'ngx-sortablejs';
import * as Sentry from '@sentry/angular';
import { UserSettingsStoreModule } from '@firebird-web/user-settings-store';

declare let hs: any;

// Skip the Auth intercept request for translation files by using HttpBackend as handler
export function createTranslateLoader(http: HttpBackend): TranslateLoader {
  return new TranslateHttpLoader(
    new HttpClient(http),
    '../assets/i18n/',
    '.json'
  );
}

// Factory function to orchestrate initialization
export function initializeApp(
  authService: AuthService,
  permissionsService: PermissionsService
) {
  return (): Promise<any> => {
    hs.graphicsDir = '/assets/highslide/graphics/';
    hs.showCredits = false;
    // First initialize authService
    return authService.initialize().then(() => {
      // After authService is initialized, initialize permissionsService
      return permissionsService.initialize();
    });
    // You can continue chaining more service initializations here if necessary
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedUiModule,
    HttpClientModule,
    RouteStoreModule,
    AlertsNotificationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer,
    }),
    OAuthModule.forRoot(),
    AppRoutingModule,
    GridstackModule,
    SortablejsModule.forRoot({}),
    UserSettingsStoreModule,
  ],
  providers: [
    provideLottieOptions({
      player: () => player,
    }),
    TitleCasePipe,
    UtcDatePipe,
    CreateListService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AuthService, PermissionsService, Sentry.TraceService], // Dependencies to inject into the factory function
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [UserSettingApiService],
      useFactory: getMetaReducers,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [UserSettingApiService],
      useFactory: getSettingsMetaReducers,
      multi: true,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
