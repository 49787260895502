import {
  RENEWABLES_EUR_COMBINED_PERMISSION,
  RENEWABLES_MAPS_PERMISSION,
  RENEWABLES_NA_COMBINED_PERMISSION,
} from './renewables-permissions.constants';
import { RenewablesPermissionsGuardData } from '../types';
import { RenewablesRoutingSubRoutes } from '../enums';

export const SHARED_RENEWABLES_PERMISSIONS_GUARD_DATA: RenewablesPermissionsGuardData =
  {
    modulePath: 'renewables',
    permissions: [],
    subRoutes: [
      {
        path: RenewablesRoutingSubRoutes.EUR,
        permission: [RENEWABLES_EUR_COMBINED_PERMISSION],
      },
      {
        path: RenewablesRoutingSubRoutes.NA,
        permission: [RENEWABLES_NA_COMBINED_PERMISSION],
      },
      {
        path: RenewablesRoutingSubRoutes.MAPS,
        permission: [RENEWABLES_MAPS_PERMISSION],
      },
    ],
    isMaps: false,
  };
