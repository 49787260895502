export const RENEWABLES_PERMISSION = 'Renewables';

export const RENEWABLES_MAPS_PERMISSION = 'Wind_Graphics_80m';

export const RENEWABLES_EUR_COMBINED_PERMISSION = 'Renewables_EUR';

export const RENEWABLES_NA_COMBINED_PERMISSION = 'Renewables_NA';

export const RENEWABLES_TAB_COMBINED_PERMISSION = 'Renewables_Tab';

export const RENEWABLES_EUR_COMBINED_PERMISSIONS = [
  'View__Renewables__EUR__Global_Models',
  'View__Renewables__EUR__GRAF',
];

export const RENEWABLES_NA_COMBINED_PERMISSIONS = [
  'View__Renewables__NA__CAISO',
  'View__Renewables__NA__ERCOT',
  'View__Renewables__NA__MISO',
  'View__Renewables__NA__NEISO',
  'View__Renewables__NA__NYISO',
  'View__Renewables__NA__PJM',
  'View__Renewables__NA__SPP',
  'View__Renewables__NA__BPA',
];

export const RENEWABLES_COMBINED_PERMISSIONS = [
  ...RENEWABLES_EUR_COMBINED_PERMISSIONS,
  ...RENEWABLES_NA_COMBINED_PERMISSIONS,
];

export const RENEWABLES_MAPS_COMBINED_PERMISSIONS = [
  'View__Wind_Graphics_80m__NA_East',
  'View__Wind_Graphics_80m__NA_West',
  'View__Wind_Graphics_80m__EUR',
  'View__Wind_Graphics_80m__MEX',
];

export const RENEWABLES_TAB_COMBINED_PERMISSIONS = [
  ...RENEWABLES_EUR_COMBINED_PERMISSIONS,
  ...RENEWABLES_NA_COMBINED_PERMISSIONS,
  ...RENEWABLES_MAPS_COMBINED_PERMISSIONS,
];
