import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as UserSettingsActions from './+state/user-settings/user-settings.actions';
import * as UserSettingsSelectors from './+state/user-settings/user-settings.selectors';
import { filter, Observable, skipUntil } from 'rxjs';
import { UserSettingService } from './user-setting.service';
import { ActivatedRoute } from '@angular/router';
import { ShareRouterPersistencePayload } from './models/user-settings.models';
import { isNil, isEmpty } from 'lodash';
import {
  SAVE_TO_CITY_VIEW_CONFIG,
  SAVE_TO_SEASONALS_CONFIG,
} from './constants/save-to-parent-urls.constant';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsFacade {
  loaded$ = this.store.pipe(
    select(UserSettingsSelectors.getUserSettingsLoaded)
  );
  allUserUnits$ = this.store.pipe(
    select(UserSettingsSelectors.getAllUnits),
    this.skipWhenLoading()
  );
  selectedPrecipUnit$ = this.store.pipe(
    select(UserSettingsSelectors.getPrecipUnit),
    this.skipWhenLoading()
  );

  selectedTempUnit$ = this.store.pipe(
    select(UserSettingsSelectors.getTempUnit),
    this.skipWhenLoading()
  );

  selectedWindUnit$ = this.store.pipe(
    select(UserSettingsSelectors.getWindUnit),
    this.skipWhenLoading()
  );

  constructor(
    private readonly store: Store,
    private readonly userSettingsService: UserSettingService
  ) {}
  public init() {
    this.store.dispatch(UserSettingsActions.initUserSettings());
  }

  public changeTempUnit(tempUnit: string) {
    this.store.dispatch(UserSettingsActions.changeTempUnit({ tempUnit }));
  }

  public changePrecipUnit(precipUnit: string) {
    this.store.dispatch(UserSettingsActions.changePrecipUnit({ precipUnit }));
  }

  public changeWindUnit(windUnit: string) {
    this.store.dispatch(UserSettingsActions.changeWindUnit({ windUnit }));
  }

  public getRouterPersistenceData(goUpBy?: number) {
    return this.userSettingsService.getRouterPersistenceData(goUpBy);
  }

  public getPersistenceDataByRoute(urlSegments: string[]) {
    return this.userSettingsService.getPersistenceDataByRoute(urlSegments);
  }

  public saveDataOnRouteNavigationStart(goUpBy?: number) {
    return this.userSettingsService.saveDataOnRouteNavigationStart(goUpBy);
  }

  public getCurrentLocationUrl(): string[] {
    return this.userSettingsService.getCurrentLocationUrlArray();
  }

  public setHomepage(urlSegments: string[]) {
    this.store.dispatch(UserSettingsActions.setHomepage({ path: urlSegments }));
  }

  public setHomepageNthLevel(
    dedicatedRoutes: string[],
    urlSegment: string,
    level: number
  ) {
    this.store.dispatch(
      UserSettingsActions.setHomepageNthLevel({
        dedicatedRoutes,
        path: urlSegment,
        level,
      })
    );
  }

  public getHomepage() {
    return this.store
      .select(UserSettingsSelectors.getHomePage)
      .pipe(
        filter((urlSegments): urlSegments is string[] => urlSegments !== null)
      );
  }

  public navigateToPersistedSubRoute(
    activatedRoute: ActivatedRoute,
    redirectData?: any,
    key?: any
  ) {
    return this.userSettingsService.navigateToPersistedSubRoute(
      activatedRoute,
      redirectData,
      key
    );
  }

  public shareRouterPersistence(payload: ShareRouterPersistencePayload): void {
    this.store.dispatch(UserSettingsActions.shareRouterPersistence(payload));
  }

  public saveState(path: string, value: any, clearPrevState = false) {
    this.userSettingsService.saveState(path, value, clearPrevState);
  }

  public getOneLevelUpRouterPersistenceData<D>() {
    return this.getRouterPersistenceData(1).pipe(
      filter((data): data is D => !isNil(data))
    );
  }

  public saveRegionDataToCityState(regionData: Record<string, string>) {
    this.saveDataToSpecificState(SAVE_TO_CITY_VIEW_CONFIG, regionData);
  }

  public saveRegionDataToSeasonalsState(regionData: Record<string, string>) {
    this.saveDataToSpecificState(SAVE_TO_SEASONALS_CONFIG, regionData);
  }

  private saveDataToSpecificState(
    stateConfig:
      | typeof SAVE_TO_CITY_VIEW_CONFIG
      | typeof SAVE_TO_SEASONALS_CONFIG,
    regionData: Record<string, string>
  ) {
    stateConfig.forEach((config) => {
      const data = config.fields.reduce((acc, field) => {
        if (!regionData[field]) {
          return acc;
        }
        return { ...acc, [field]: regionData[field] };
      }, {});
      if (!isEmpty(data)) {
        this.saveState(config.url, data);
      }
    });
  }

  private skipWhenLoading<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        skipUntil(this.store.pipe(select(UserSettingsSelectors.loaded)))
      );
  }
}
