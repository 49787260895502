import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, firstValueFrom } from 'rxjs';
import { environment } from '@firebird-web/shared-config';
import { combinedPermissionsOrMapping } from './permissions.constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  usersPermissions: any = {};
  private permissions$: BehaviorSubject<string[] | Record<string, unknown>> =
    new BehaviorSubject(this.usersPermissions);

  constructor(private readonly http: HttpClient) {}

  public initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      const endPointUrl = `${environment.apiDomain}/api/v1/permissions/get-permissions`;
      try {
        firstValueFrom(this.http.get<string[]>(endPointUrl))
          .then((data) => this.generateCombinedOrPermissions(data))
          .then((data) => {
            if (Array.isArray(data)) {
              const splittedPermissions = data.map((item: string) =>
                item
                  .split('__')
                  .reverse()
                  .reduce(
                    (acc: Record<string, unknown>, permission: string) => {
                      return {
                        [permission]: Object.keys(acc).length
                          ? { ...acc }
                          : true,
                      };
                    },
                    {}
                  )
              );
              const isObject = (item: any) => {
                return item && typeof item === 'object' && !Array.isArray(item);
              };
              const mergeDeep: any = (target: any, ...sources: any[]) => {
                if (!sources.length) return target;
                const source = sources.shift();

                if (isObject(target) && isObject(source)) {
                  for (const key in source) {
                    if (isObject(source[key])) {
                      if (!target[key]) Object.assign(target, { [key]: {} });
                      mergeDeep(target[key], source[key]);
                    } else {
                      Object.assign(target, { [key]: source[key] });
                    }
                  }
                }
                return mergeDeep(target, ...sources);
              };

              this.usersPermissions = mergeDeep({}, ...splittedPermissions);
              this.permissions$ = new BehaviorSubject(this.usersPermissions);
              resolve();
            }
          });
      } catch (error) {
        console.error('Error fetching permissions:', error);
        reject();
      }
    });
  }

  generateCombinedOrPermissions(permissions: string[]): string[] {
    const combinedPermissions = combinedPermissionsOrMapping
      .map((item) => {
        return item.permissions.some((permission) =>
          item?.strictCheck
            ? permissions.some(
                (permissionString) => permissionString === permission
              )
            : permissions.some((permissionString) =>
                permissionString.includes(permission)
              )
        )
          ? item.name
          : undefined;
      })
      .filter((item): item is string => !!item);
    return [...permissions, ...combinedPermissions];
  }

  getPermissions() {
    return this.usersPermissions || {};
  }

  getPermissionsAsync() {
    return this.permissions$;
  }

  isViewPermittedAsync(permission: string[], key = 'View') {
    return this.permissions$.pipe(
      map((userPermissions) =>
        this.isPermitted(permission, key, userPermissions)
      )
    );
  }

  isPermitted(
    permission: string[],
    key = 'View',
    userPermissions = this.getPermissions()
  ): boolean {
    return !!permission.reduce((acc: unknown, item: string) => {
      if (typeof acc === 'boolean') {
        return acc;
      }
      if (Array.isArray(acc)) {
        return acc.includes(item);
      }
      if (key === 'PoolSet') {
        const keys = Object.keys(acc as Record<string, unknown>);
        return keys
          .filter((k) => k.includes(item))
          .some((k) => (acc as Record<string, unknown>)[k]);
      }
      if (typeof acc === 'object') {
        return acc?.[item as keyof typeof acc] ?? false;
      }
      return acc;
    }, (userPermissions as Record<string, unknown>)?.[key]);
  }

  getFirstAvailableContinent(
    permission: string[],
    key = 'View',
    userPermissions = this.getPermissions()
  ): string {
    const permittedRegionsObg = permission.reduce(
      (acc: unknown, item: string) => {
        return acc?.[item as keyof typeof acc] || ({} as object);
      },
      (userPermissions as Record<string, unknown>)?.[key]
    );

    return Object.keys(permittedRegionsObg as object)[0] ?? '';
  }
}
