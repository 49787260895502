import {
  RENEWABLES_MAPS_PERMISSION,
  RENEWABLES_PERMISSION,
} from './renewables-permissions.constants';
import { IToggleTableInput } from '@firebird-web/shared-ui';

export const toggleHeatMapActive: IToggleTableInput[] = [
  {
    labelKey: 'heatMap',
    iconName: 'layers-grid-add-1',
    permission: [RENEWABLES_PERMISSION],
  },
  {
    labelKey: 'maps',
    iconName: 'maps-pin-black',
    permission: [RENEWABLES_MAPS_PERMISSION],
  },
];

export const toggleMapsActive: IToggleTableInput[] = [
  {
    labelKey: 'heatMap',
    iconName: 'layers-grid-add-1-inactive',
    permission: [RENEWABLES_PERMISSION],
  },
  {
    labelKey: 'maps',
    iconName: 'maps-pin',
    permission: [RENEWABLES_MAPS_PERMISSION],
  },
];
