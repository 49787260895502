import {
  RENEWABLES_EUR_COMBINED_PERMISSIONS,
  RENEWABLES_MAPS_COMBINED_PERMISSIONS,
  RENEWABLES_COMBINED_PERMISSIONS,
  RENEWABLES_NA_COMBINED_PERMISSIONS,
  RENEWABLES_PERMISSION,
  RENEWABLES_TAB_COMBINED_PERMISSION,
  RENEWABLES_TAB_COMBINED_PERMISSIONS,
  RENEWABLES_MAPS_PERMISSION,
  RENEWABLES_EUR_COMBINED_PERMISSION,
  RENEWABLES_NA_COMBINED_PERMISSION,
} from '../../../renewable/src/lib/constants';

export const combinedPermissionsOrMapping: {
  name: string;
  permissions: string[];
  strictCheck?: boolean;
}[] = [
  {
    name: 'View__Blurb_Blog',
    permissions: ['View__Forecaster_Blogs', 'View__Forecaster_Blurbs'],
  },
  {
    name: 'View__FreshLook_Table',
    permissions: [
      'View__FreshLook_Table__NA__Gold_East',
      'View__FreshLook_Table__NA__Gold_West',
    ],
  },
  {
    name: 'View__Detailed_Discussion',
    permissions: [
      'View__Forecaster_Discussions__1_to_5_Day__NA',
      'View__Forecaster_Discussions__1_to_5_Day__EUR',
      'View__Forecaster_Discussions__Medium_Range__NA',
      'View__Forecaster_Discussions__Medium_Range__EUR',
    ],
  },
  {
    name: 'View__Midday_Update',
    permissions: [
      'View__Midday_Blogs',
      'View__FirstLook',
      'View__Midday_Model_Analysis_Graphics',
    ],
  },
  {
    name: `View__${RENEWABLES_TAB_COMBINED_PERMISSION}`,
    permissions: RENEWABLES_TAB_COMBINED_PERMISSIONS,
  },
  {
    name: `View__${RENEWABLES_MAPS_PERMISSION}`,
    permissions: RENEWABLES_MAPS_COMBINED_PERMISSIONS,
  },
  {
    name: `View__${RENEWABLES_PERMISSION}`,
    permissions: RENEWABLES_COMBINED_PERMISSIONS,
  },
  {
    name: `View__${RENEWABLES_EUR_COMBINED_PERMISSION}`,
    permissions: RENEWABLES_EUR_COMBINED_PERMISSIONS,
  },
  {
    name: `View__${RENEWABLES_NA_COMBINED_PERMISSION}`,
    permissions: RENEWABLES_NA_COMBINED_PERMISSIONS,
  },
  {
    name: 'PoolSet__ISO-Locations',
    permissions: [
      'PoolSet__NA',
      'PoolSet__EUR',
      'PoolSet__ASIA',
      'PoolSet__MEX',
      'PoolSet__WF-NA-POWER',
    ],
    strictCheck: true,
  },
];
